//Node Modules
import React, { ReactElement, useState } from 'react';

//Material UI
import { Box, TextField, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

//Internal
import { NodeDialog, NodeIconButton } from '../../Shared/components';
import { ENTITIES_EDIT_URL } from '../../Shared/constants/routes';
import { NodeFull } from '../../Shared/interfaces';
import CardFiltersKVP from './EntityDetails/CardFiltersKVP';
import { editEntityValidator } from '../constants/validationSchemas';
import { EntityCardKVP } from '../../Shared/interfaces/EntityCardKVP';

type EntityKVP = { key: string; value: string };

interface WithCardOnEntity {
	card: { identifierField: string };
}

export const EditEntity = (props: { reRenderComponent: Function; entity: NodeFull }): ReactElement => {
	const { entity, reRenderComponent } = props;
	const [name, setName] = useState<string>(entity.name || '');
	const [KVPs, setKVPs] = useState<EntityKVP[]>((entity.cardFiltersKVP as EntityCardKVP[]) || []);

	const relatedCardsIdentifyFields: string[] = [];
	if (entity.CardOnEntity?.length) {
		entity.CardOnEntity.forEach((COE: WithCardOnEntity) => {
			if (COE.card.identifierField) {
				relatedCardsIdentifyFields.push(COE.card.identifierField);
			}
		});
	}

	const entityData: Partial<NodeFull> = {
		entityData: {
			id: entity.id,
			name: name,
			cardFiltersKVP: KVPs,
			deleted: entity.deleted,
			synced: entity.synced,
		},
		graphData: {
			vertical: entity.vertical,
			level: entity.level,
		},
	};

	const handleKVPsChange = (newKVPs: EntityKVP[]) => {
		setKVPs(newKVPs);
	};

	const onClose = () => {
		setKVPs(entity.cardFiltersKVP as EntityCardKVP[]);
		setName(entity.name);
	};

	return (
		<NodeDialog
			ButtonComponent={NodeIconButton}
			url={ENTITIES_EDIT_URL}
			node={entityData}
			dialogText={'Edit'}
			dialogTitle={'Edit Entity'}
			reRenderComponent={reRenderComponent}
			buttonColor={'primary'}
			Icon={EditOutlinedIcon}
			dialogContentText="Editing entity details. Name must be unique. Please include any relevant values for data lookup in DOMO."
			validationSchema={editEntityValidator}
			resetContent={onClose}
		>
			<Box sx={{ marginTop: '10px' }}>
				<Typography sx={{ fontWeight: 700, fontSize: '14px', marginBottom: '5px' }}>Details</Typography>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Entity Name"
					type="text"
					fullWidth
					variant="outlined"
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
			</Box>

			<Box sx={{ marginTop: '10px' }}>
				<Typography sx={{ fontWeight: 700, fontSize: '14px', marginBottom: '5px' }}>Lookup Values</Typography>
				<CardFiltersKVP KVPs={KVPs} onChange={handleKVPsChange} options={relatedCardsIdentifyFields} />
			</Box>
		</NodeDialog>
	);
};
