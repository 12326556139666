import React, { useEffect, useState } from 'react';
import { Autocomplete, capitalize, FormControl, FormLabel, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../store/store';
import { ROLES } from '../../../Shared/constants/roles';
import { useFetch } from '../../../Shared/hooks/useFetch';
import { ResponseHandler } from '../../../Shared/components';

interface EntityGraphFieldsSelectorProps {
	reqUrl: string;
	fieldName: string
	onChange: (value: string) => void
}

const EntityGraphFieldsSelector = (props: EntityGraphFieldsSelectorProps): React.ReactElement => {
	const { reqUrl, fieldName, onChange } = props;
	const user = useSelector((state: StoreState) => state.user);
	const [ options, setOptions ] = useState<string[]>([])

	const userIsAdmin = user.role === ROLES.ADMIN;
	const request = useFetch<string[]>(reqUrl);

	useEffect(() => {
		if (request.success && request.data) {
			setOptions(request.data);
		}
	}, [request.success, request.data])

	const handleChange = (_event: React.SyntheticEvent, option: string | null): void => {
		if (option) {
			onChange(option);
		}
	};

	const handleInputChange = (value: string): void => {
        if (userIsAdmin && value) {
            onChange(value);
        }
    };

	return <FormControl sx={{width: "100%"}}>
		<FormLabel sx={{padding: "8px 0"}}>{titleCase(fieldName)}</FormLabel>
		<ResponseHandler request={request}>
			<Autocomplete
				freeSolo={userIsAdmin}
				renderInput={ (params) => <TextField {...params} /> }
				options={options.map((option) => titleCase(option))}
				onInputChange={(_, newValue) => {
					handleInputChange(newValue);
				}}
				onChange={handleChange}
			/>
		</ResponseHandler>
	</FormControl>
}

export default EntityGraphFieldsSelector;

const titleCase = (str: string): string => {
	return str.toLowerCase().split(' ').map(word => capitalize(word)).join(' ');
}
