//Node Modules
import React from 'react';

//Material UI
import { Box } from '@mui/material';
import { NodeType } from '../../../Shared/interfaces';
import UserTable from './UserTable';
import { EntityTable } from './EntityTable';
import { InstanceTable } from './InstanceTable';
import { EntityData } from './EntityData';
import CardTable from './CardTable';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { EntityKVPTable } from './EntityKVPTable';

//Internal

export const EntityDetailsContent = (props: { tab: number; id: string | undefined; nodeType: NodeType; data: any }) => {
	const { tab, data } = props;
	const theme = useTheme();

	const standardScreen = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<>
			{tab === 0 ? <Box
				sx={{
					width: standardScreen ? '85%' : '100%',
					overflowY: 'auto',
					'&::-webkit-scrollbar': {
						background: '#ffffff',
						borderRadius: '10px',
						width: '.75em',
						[theme.breakpoints.down('md')]: {
							display: 'none',
						},
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#00000023',
						border: '4px solid transparent',
						borderRadius: '9px',
						backgroundClip: 'content-box',
					},
					'&::-webkit-scrollbar-track': {
						background: '#ffffff',
						borderRadius: '10px',
						outline: '#00000023 solid 2px',
						zIndex: 100,
					},
					'&::-webkit-scrollbar-button': {
						height: '2.5%',
					},
				}}
			>
				<EntityData data={data} />
			</Box> : null}
			{tab === 1 ? <Box sx={{ width: standardScreen ? '85%' : '100%', height: '100%' }}>
				<InstanceTable instances={data?.instances ?? []} />
			</Box> : null}
			{tab === 2 ? <Box sx={{ width: standardScreen ? '85%' : '100%', height: '100%' }}>
				<UserTable users={data.users ?? []} />
			</Box> : null}
			{tab === 3 ? <Box sx={{ width: standardScreen ? '85%' : '100%', height: '100%' }}>
				<CardTable cards={data.cards ?? []} />
			</Box> : null}
			{tab === 4 ? <Box sx={{ width: standardScreen ? '85%' : '100%', height: '100%' }}>
				<EntityTable entities={data.entities} />
			</Box> : null}
			{tab === 5 ? <Box sx={{ width: standardScreen ? '85%' : '100%', height: '100%' }}>
				<EntityKVPTable data={data.cardFiltersKVP} />
			</Box> : null}
		</>
	);
};
