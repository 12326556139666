import React, { useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { DASHBOARD_IMPERSONATED, DASHBOARD_ROOT_URL } from '../Shared/constants/routes';
import { useFetch } from '../Shared/hooks/useFetch';
import { ResponseHandler } from '../Shared/components';
import DashboardCanvas from './components/DashboardCanvas';
import { Box } from '@mui/material';
import { DashboardBar } from './components/DashboardBar';
import { useSelector } from 'react-redux';
import { UserState } from '../../store/userReducer';
import { StoreState } from '../../store/store';
import ErrorBoundary from '../Core/components/ErrorBoundary/ErrorBoundary';

const Dashboard2 = (): any => {
	const params = useParams<{ dashboardId?: string }>();
	const location = useLocation() as any;
	const state = location.state;
	const userState: UserState = useSelector((state: StoreState) => state.user);

	const [reRender, setReRender] = useState<boolean>(false);

	const reRenderComponent = (): void => {
		setReRender(!reRender);
	};

	const fetchURL = state?.impersonatedUserId ? DASHBOARD_IMPERSONATED : `${DASHBOARD_ROOT_URL}/${params.dashboardId ?? ''}`;
	const payload = state?.impersonatedUserId ? { impersonatedUserId: state?.impersonatedUserId } : null;

	const dashboardResponse = useFetch<any>(fetchURL, true, [reRender], payload, !!payload);

	return (
		<ErrorBoundary>
			<ResponseHandler request={dashboardResponse}>
				<Box sx={{ width: '100%', position: 'relative' }}>
					<DashboardBar
						name={dashboardResponse.data?.name}
						currentDashboardId={dashboardResponse.data?.id}
						provisioned={userState.provisioned}
					/>
					<DashboardCanvas
						cardInstances={dashboardResponse.data?.cardInstances}
						impersonatedUserId={dashboardResponse.data?.impersonatedUserId}
						impersonation={dashboardResponse.data?.impersonation}
						dashboardId={dashboardResponse.data?.id}
						allowEdit={dashboardResponse.data?.allowEdit}
						reRenderComponent={reRenderComponent}
						defaultDashboardId={dashboardResponse.data?.defaultDashboardId}
					/>
				</Box>
			</ResponseHandler>
		</ErrorBoundary>
	);
};

export default Dashboard2;
