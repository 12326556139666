//Node Modules
import React, { ReactElement, useState } from 'react';

//Material UI
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

//Internal
import { NodeDialog, NodeIconButton } from '../../Shared/components';
import { USER_EDIT_URL } from '../../Shared/constants/routes';
import { ROLES } from '../../Shared/constants/roles';
import { DefaultDashboardSelect } from './DefaultDashboardSelect';
import { NodeBase } from '../../Shared/interfaces';

export const EditUser = (props: { reRenderComponent: Function; user: NodeBase }): ReactElement => {
	const { user, reRenderComponent } = props;

	const [firstName, setFirstName] = useState<string>(user.firstName as string);
	const [lastName, setLastName] = useState<string>(user.lastName as string);
	const [dashboardId, setDashboardId] = useState<string | undefined>(user.defaultDashboardId as string);
	const [role, setRole] = useState<number>(user.role as number);

	const userData: NodeBase = {
		...user,
		firstName: firstName,
		lastName: lastName,
		email: user.email,
		role: role,
		defaultDashboardId: dashboardId,
	};

	const onClose = (): void => {
		setFirstName(user.firstName as string)
		setLastName(user.lastName as string)
		setDashboardId(user.defaultDashboardId as string)
		setRole(user.role as number)
	}

	return (
		<NodeDialog
			ButtonComponent={NodeIconButton}
			url={USER_EDIT_URL}
			node={userData}
			dialogText={'Edit'}
			dialogTitle={'Edit User'}
			reRenderComponent={reRenderComponent}
			buttonColor={'primary'}
			variant={'contained'}
			dialogContentText="Editing user details. Default Dashboard should be selected from Public dashboards"
			Icon={EditOutlinedIcon}
			resetContent={onClose}
		>
			<Box sx={{ marginTop: '10px' }}>
				<Typography sx={{ fontWeight: 700, fontSize: '14px', marginBottom: '5px' }}>Personal Details</Typography>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="First Name"
					type="text"
					fullWidth
					variant="outlined"
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
				/>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Last Name"
					type="text"
					fullWidth
					variant="outlined"
					value={lastName}
					onChange={(e: any) => setLastName(e.target.value)}
				/>
			</Box>

			<Box sx={{ marginTop: '10px' }}>
				<Typography sx={{ fontWeight: 700, fontSize: '14px', marginBottom: '5px' }}>Permissions</Typography>
				<DefaultDashboardSelect userId={user.id as string} dashboardId={dashboardId} setDashboardId={setDashboardId} />
				<FormControl style={{ marginTop: '16px' }} fullWidth>
					<InputLabel>Role</InputLabel>
					<Select
						labelId="role-select-label"
						id="role-select"
						label="Role"
						onChange={(e) => setRole(e.target.value as number)}
						value={role}
					>
						<MenuItem value={ROLES.USER}>User</MenuItem>
						<MenuItem value={ROLES.AUTOMATIT}>Automatit</MenuItem>
						<MenuItem value={ROLES.ADMIN}>Admin</MenuItem>
					</Select>
				</FormControl>
			</Box>
		</NodeDialog>
	);
};
