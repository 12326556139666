//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { List, ListItem, ListItemText, TableCell, TableRow } from '@mui/material';

//Internal
import { TableArrayFieldProps, TableListItem } from '../interfaces';

export const TableArrayField = (props: TableArrayFieldProps): ReactElement => {
	const { array, label } = props;

	return (
		<TableRow>
			<TableCell>{label}</TableCell>
			<TableCell sx={{ width: '85%' }}>
				<List>
					{array.map((arrayItem: TableListItem) => {
						return (
							<ListItem key={`card-${arrayItem.id}`}>
								<ListItemText>{arrayItem.name ?? `${arrayItem.firstName}  ${arrayItem.lastName}`}</ListItemText>
							</ListItem>
						);
					})}
				</List>
			</TableCell>
		</TableRow>
	);
};
