//Node Modules
import React, { useState } from 'react';

//Material UI
import { Box, TextField, Typography } from '@mui/material';
import { formatDate } from '../../../../utils/dateFormatter';

//Internal

const DataField = (props: { edit: Boolean; label: string; value: string }) => {
	const { edit, label, value } = props;
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', flexBasis: '50%', marginBottom: '25px' }}>
			<Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{label}</Typography>
			{edit ? (
				<TextField id="standard-helperText" defaultValue="Default Value" variant="standard" />
			) : (
				<Typography sx={{ fontWeight: 700, fontSize: '16px' }}>{value}</Typography>
			)}
		</Box>
	);
};

export const EntityData = (props: { data: any }) => {
	const [edit] = useState<boolean>(false);
	const { data } = props;

	return (
		<Box
			id="infoSection"
			sx={{
				padding: '2% 2%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around',
				minHeight: '100%',
			}}
		>
			<Box
				sx={{
					padding: '20px 20px 0px 20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>
					Basic Info
				</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<DataField edit={edit} label={'Entity Name'} value={data.name} />
					<DataField edit={edit} label={'Id'} value={data.id} />
					<DataField edit={edit} label={'Status'} value={data.delete ? 'Disabled' : 'Active'} />
					<DataField edit={edit} label={'Synced'} value={data.synced ? 'True' : 'False'} />
				</Box>
			</Box>
			<Box
				sx={{
					padding: '20px 20px 0px 20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					flexDirection: 'column',
					margin: '10px 0',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>
					Timestamps
				</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<DataField edit={edit} label={'Created On'} value={formatDate(data.createdOn)} />
					<DataField edit={edit} label={'Created By'} value={data.createdBy} />
					<DataField edit={edit} label={'Modified On'} value={formatDate(data.modifiedOn)} />
					<DataField edit={edit} label={'Modified By'} value={data.modifiedBy} />
				</Box>
			</Box>
			<Box
				sx={{
					padding: '20px 20px 0px 20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>Usage</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<DataField edit={edit} label={'Entities'} value={data.entities?.length ?? 0} />
					<DataField edit={edit} label={'Users'} value={data.users?.length ?? 0} />
					<DataField edit={edit} label={'Instances'} value={data.instances?.length ?? 0} />
				</Box>
			</Box>
		</Box>
	);
};
