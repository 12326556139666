//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { DialogContentText } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

//Internal
import { NodeDialog, NodeIconButton } from '../../Shared/components';
import { USER_DELETE_URL } from '../../Shared/constants/routes';
import { NodeBase } from '../../Shared/interfaces';
import { DeleteUserProps } from '../interfaces';

export const DeleteUser = (props: DeleteUserProps): ReactElement => {
	const { reRenderComponent, name, deleteUserId } = props;

	const user: NodeBase = {
		name: name,
		deleteUserId: deleteUserId,
	};

	return (
		<NodeDialog
			ButtonComponent={NodeIconButton}
			url={USER_DELETE_URL}
			node={user}
			dialogText={'Delete'}
			dialogTitle={'Delete User'}
			reRenderComponent={reRenderComponent}
			Icon={DeleteOutlineIcon}
		>
			<DialogContentText>{`Are you sure you want to delete user ${name}?`}</DialogContentText>
		</NodeDialog>
	);
};
