//Node Modules
import React, { ReactElement, ChangeEvent } from 'react';

//Material UI
import { TableCell, TableRow, TextField } from '@mui/material';

//Internal
import { TableFieldProps } from '../interfaces';

export const TableField = (props: TableFieldProps): ReactElement => {
	const { nodeCurrent, setNodeCurrent, label, relatedValue } = props;

	return (
		<TableRow>
			<TableCell>{label}</TableCell>
			<TableCell sx={{ width: '85%' }}>
				<TextField
					fullWidth
					variant="standard"
					autoComplete="off"
					value={nodeCurrent[relatedValue]}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						setNodeCurrent({ ...nodeCurrent, [relatedValue]: e.target.value })
					}
				/>
			</TableCell>
		</TableRow>
	);
};
