//Node Modeules
import React, { ReactElement } from 'react';

//Material UI
import { NodeBarButtonProps } from '../interfaces';
import { Button, Box } from '@mui/material';

export const NodeBarButton = (props: NodeBarButtonProps): ReactElement => {
	const { setOpen, buttonText, color, sx, disableAfter, variant } = props;

	return (
		<>
			{disableAfter ? null : (
				<Box sx={{ border: `1px solid #00000040`, borderRadius: '4px', width: '37%', height: '2px', margin: '2px' }} />
			)}

			<Button
				onClick={(): void => setOpen(true)}
				variant={variant ?? 'text'}
				color={color}
				size="large"
				sx={{
					textTransform: 'capitalize',
					textDecoration: 'none',
					padding: '0',
					marign: '0',
					marginLeft: `0 !important`,
					'&:hover': {
						background: 'none',
					},
					...sx,
				}}
			>
				{buttonText}
			</Button>
		</>
	);
};
