// This function pluralizes a string by adding 's' to the end
// of the word, unless the final letter is 'y', in which case
// the 'y' is replaced with 'ies'. The function takes a string
// as an argument and returns a string.

export const dumbPluralize = (string: string): string => {
	const finalLetter: string = string.slice(-1);

	switch (finalLetter) {
		case 'y':
			return string.slice(0, -1) + 'ies';
		default:
			return string + 's';
	}
};
