//Node Modules
import React, { ReactElement, useEffect, useState } from 'react';

//Material UI
import { Box, TextField } from '@mui/material';

//Internal
import { NodeDialog } from '../../../Shared/components';
import { CARD_INSTANCE_CREATE_URL } from '../../../Shared/constants/routes';
import InstanceBaseSelect from './InstanceBaseSelect';
import { Shape } from '../../interfaces/Shapes';
import { CreateCardInstanceButton } from './CreateCardInstanceButton';
import { createCardInstanceValidation } from '../../constants/validationSchemas';
import { CardFilters } from '../../../Shared/components/CardFilters';
import { NodeBase } from '../../../Shared/interfaces';

interface NewCardInstance extends NodeBase {
	dashboardId: string;
	name: string;
	originCardId: string;
	positionX: number;
	positionY: number;
	filterFields: any[];
	userId: string | null;
}

export const CreateCardInstance = (props: {
	reRenderComponent: Function;
	dashboardId: string;
	shapes: Shape[];
	disabled: boolean;
	impersonatedUserId?: string;
}): ReactElement => {
	const { dashboardId, shapes, reRenderComponent, disabled, impersonatedUserId } = props;

	const [name, setName] = useState<string>('');
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [originCardId, setOriginCardId] = useState<string>('');
	const [filterFields, setFilterFields] = useState<any>([]);

	const resetContent = (): void => {
		setName('');
		setOriginCardId('');
	};

	const getShapePosition = () => {
		const rowValues = [0, ...getRowValues()];
		const position = getGuessX(0, rowValues);

		setPosition({ x: position.x, y: position.y });
	};

	const getRowValues = () => {
		const yArray = shapes.map((shape: Shape) => {
			return shape.positionY + shape.height;
		});

		return removeDuplicateRows(yArray);
	};

	function removeDuplicateRows(rowPosArray: number[]) {
		const seen: any = { 0: true };
		const uniques = rowPosArray.filter((rowPos: number) => {
			return seen.hasOwnProperty(rowPos) ? false : (seen[rowPos] = true);
		});

		return uniques.sort((a, b) => {
			return a - b;
		});
	}

	const getGuessX = (minX: number, rowValues: number[], index: number = 0): any => {
		const maxX = minX + 300; //New Card Width
		const maxY = rowValues[index] + 300; //New Card Height

		//Calculate overlap (Maybe I can just repurpose aabb here)
		const filteredShapes = shapes.filter((shape) => {

			return (
				(shape.positionX < maxX && //1
					shape.positionX >= minX && //2 Does positionX fall inside range of minX-maxX
					shape.positionY + shape.height > rowValues[index]) || //3
				//This was originally Included but found a bug resulting in scenario where test Pos exists entirely within filtered shape
				//e.g. shape starts before guessPos but ends after guessPos
				// shape.positionY + shape.height <= rowValues[index] + 300) || //4 //5

				(shape.positionY < maxY && //6
					shape.positionY >= rowValues[index] && //7
					shape.positionX < maxX && //8
					shape.positionX >= minX) //9 //10
			);
		});

		if (filteredShapes.length === 0) {
			return { x: minX, y: rowValues[index] };
		}

		const furthestRight = filteredShapes.reduce((prev, current) => (prev.positionX > current.positionX ? prev : current));
		//Get New Test Position
		const newPos = furthestRight.positionX + furthestRight.width;
		//console.log('newPos', newPos);

		//Test Next Dynamic Next Row
		if (newPos + 200 > window.innerWidth - 250 - 42) {
			//console.log('new Y', rowValues[index + 1]);
			//Recurse Next Row
			return getGuessX(0, rowValues, index + 1);
		}

		//console.log('same Y', rowValues[index]);

		//Recurse
		return getGuessX(newPos, rowValues, index);
	};

	const newCardInstance: NewCardInstance = {
		dashboardId: dashboardId,
		name: name,
		originCardId: originCardId,
		positionX: position.x,
		positionY: position.y,
		filterFields: filterFields,
		userId: impersonatedUserId ?? null,
	};

	useEffect(() => {
		getShapePosition();
		// eslint-disable-next-line
	}, [originCardId]);

	return (
		<NodeDialog
			ButtonComponent={CreateCardInstanceButton}
			url={CARD_INSTANCE_CREATE_URL}
			resetContent={resetContent}
			node={newCardInstance}
			dialogText={'Create'}
			dialogTitle={'Add Chart to Dashboard'}
			validationSchema={createCardInstanceValidation}
			reRenderComponent={reRenderComponent}
			disabled={disabled}
		>
			<TextField
				autoFocus
				margin="dense"
				id="name"
				label="Name"
				type="text"
				fullWidth
				variant="standard"
				value={name}
				onChange={(e) => setName(e.target.value)}
			/>
			<Box sx={{ marginTop: '20px', width: '300px' }}>
				<InstanceBaseSelect
					originCardId={originCardId}
					setOriginCardId={setOriginCardId}
					impersonatedUserId={impersonatedUserId}
				/>
			</Box>
			<Box style={{width: '500px'}}>
				<CardFilters onChange={setFilterFields} filterFields={filterFields} />
			</Box>
		</NodeDialog>
	);
};
