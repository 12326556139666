import React from 'react';
import { DetailsTable } from '../../../Shared/components/DetailsTable';

export const EntityKVPTable = (props: {data: any}): React.ReactElement => {
	const { data } = props;

	const columns = [
		{ field: 'key', headerName: 'Key', minWidth: 600 },
		{ field: 'value', headerName: 'Value', minWidth: 600 },
	];

	const rows = data.map((kvp: {key: string, value: string}) => {
		return {
			id: kvp.key,
			key: kvp.key,
			value: kvp.value,
		}
	})

	return <DetailsTable columns={columns} rows={rows} />
}