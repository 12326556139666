import * as yup from 'yup';

export const editUserValidator = yup.object().shape({
	firstName: yup.string().min(3).trim(),
	lastName: yup.string().min(3).trim(),
	defaultDashboardId: yup.string().min(3).trim(),
});

export const createUserValidation = yup.object().shape({
	firstName: yup.string().min(3).trim().required(),
	lastName: yup.string().min(3).trim(),
	email: yup.string().trim().lowercase().min(3).email().required(),
	defaultDashboardId: yup.string().min(3).trim(),
	role: yup.number().max(3)
});
