//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { DialogContentText } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

//Internal
import { ENTITIES_DELETE_URL } from '../../Shared/constants/routes';
import { NodeDialog } from '../../Shared/components';
import { DeleteEntityProps } from '../interfaces';
import { NodeIconButton } from '../../Shared/components/NodeIconButton';

export const DeleteEntity = (props: DeleteEntityProps): ReactElement => {
	const { reRenderComponent, name, deleteEntityId } = props;

	const entity = {
		name: name,
		deleteEntityId: deleteEntityId,
	};

	return (
		<NodeDialog
			ButtonComponent={NodeIconButton}
			url={ENTITIES_DELETE_URL}
			node={entity}
			dialogText={'Delete'}
			dialogTitle={'Delete Entity'}
			reRenderComponent={reRenderComponent}
			Icon={DeleteOutlineIcon}
		>
			<DialogContentText>{`Are you sure you want to delete entity ${name}?`}</DialogContentText>
		</NodeDialog>
	);
};
