//Internal
import { Box } from '@mui/material';
import { TableArrayField, TableField, TableReadOnlyField } from '../../Shared/components';

const UserDetailsTableRows = (props: any): React.ReactElement => {
	const { node, nodeCurrent, setNodeCurrent } = props;

	return (
		<Box
			sx={{
				overflow: 'scroll',
				width: window.innerWidth - 250,
				minHeight: window.innerHeight / 2 - 32 - 48,
				maxHeight: window.innerHeight / 2 - 32 - 48,
			}}
		>
			<TableReadOnlyField nodeCurrent={nodeCurrent} label="Id" relatedValue={'id'} />
			<TableField
				label={'First Name'}
				relatedValue={'firstName'}
				nodeCurrent={nodeCurrent}
				setNodeCurrent={setNodeCurrent}
			/>
			<TableField label={'Last Name'} relatedValue={'lastName'} nodeCurrent={nodeCurrent} setNodeCurrent={setNodeCurrent} />
			<TableReadOnlyField nodeCurrent={nodeCurrent} label="Created" relatedValue={'createdOn'} />
			<TableReadOnlyField nodeCurrent={nodeCurrent} label="Active" relatedValue={'isActive'} />
			<TableArrayField array={node.entities} label="Related Entities" />
		</Box>
	);
};

export default UserDetailsTableRows;
