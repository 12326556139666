import * as React from 'react';
import { DetailsTable } from '../../../Shared/components/DetailsTable';
//import { IconButton } from '@mui/material';
//import NavigateNextIcon from '@mui/icons-material/NavigateNext';
//import { useNavigate } from 'react-router-dom';

export const EntityTable = (props: { entities: any }) => {
	const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short', timeZone: 'US/Arizona' });
	//const navigate = useNavigate();
	const { entities } = props;

	const columns = [
		{ field: 'name', headerName: 'Name', minWidth: 85 },
		{ field: 'id', headerName: 'Id', minWidth: 170 },
		{
			field: 'createdOn',
			headerName: 'Created',
			minWidth: 120,
			align: 'left',
			valueFormatter: (params: any) => {
				const date = new Date(params?.value);
				return dateFormatter.format(date);
			},
		},
		{
			field: 'modifiedOn',
			headerName: 'Modified',
			minWidth: 120,
			align: 'left',
			format: (params: any) => {
				const date = new Date(params?.value);
				return dateFormatter.format(date);
			},
		},
		// {
		// 	field: 'more',
		// 	minWidth: 25,
		// 	alight: 'left',
		// 	format: (value: string) => {
		// 		return (
		// 			<IconButton onClick={() => navigate(`/entities/${value}`)}>
		// 				<NavigateNextIcon />
		// 			</IconButton>
		// 		);
		// 	},
		// },
	];

	const rows = entities.map((entity: any) => {
		const entityRow = {
			key: entity.id,
			more: entity.id,
			...entity,
		};

		return entityRow;
	});

	return <DetailsTable rows={rows} columns={columns} />;
};
