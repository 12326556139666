//Node Modules
import { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

//Internal
import { buildHttpRequest, checkTokenExpiration } from '../../../utils';
import { useErrorBoundary } from 'react-error-boundary';
import { FetchState } from '../interfaces';
import { ErrorHandler } from '../../../utils/errorHandler';

const { REACT_APP_AUTH0_AUDIENCE, REACT_APP_AUTH0_SCOPE, REACT_APP_API_URL } = process.env;

type SnackVariants = 'default' | 'error' | 'success' | 'warning' | 'info';

const initState: FetchState = {
	data: null,
	success: false,
	error: null,
	loading: true,
};

export function useFetch<T = any>(
	url: string,
	passJWT: boolean = true,
	triggers: any[] | null = null,
	payload: any = null,
	isPost: boolean = false,
	failSilently: boolean = false
): FetchState<T> {
	const fullURL: string = `${REACT_APP_API_URL}${url}`;

	const [accessToken, setAccessToken] = useState<string>('');
	const [state, setState] = useState<FetchState>(initState);
	const { getAccessTokenSilently } = useAuth0();
	const isMounted = useRef<boolean>(true);
	const navigate = useNavigate();

	const { enqueueSnackbar } = useSnackbar();
	const { showBoundary } = useErrorBoundary();

	const showSnack = (message: string, variant: SnackVariants) => {
		enqueueSnackbar(message, {variant: variant});
	}

	const logOutRedirect = async (): Promise<void> => {
		navigate({pathname: '/loggedOut'});
	}

	const errorBoundary = (message?: string): void => {
		showBoundary({ message: message ? message : 'Unexpected error', isHandled: true });
	}

	const errorHandler = new ErrorHandler(logOutRedirect, showSnack, errorBoundary);

	useEffect(
		() => {
			setState(initState);

			const getToken = async (): Promise<string> => {
				const token = await getAccessTokenSilently({
					authorizationParams: {
						audience: REACT_APP_AUTH0_AUDIENCE,
						scope: REACT_APP_AUTH0_SCOPE,
					}
				});
				setAccessToken(token);

				return token;
			};

			const call = async () => {
				try {
					let token = accessToken;
					let response;
					let json;

					if (passJWT) {
						if (!accessToken || checkTokenExpiration(accessToken)) {
							token = await getToken();
						}
					}

					response = await fetch(fullURL, buildHttpRequest(payload, passJWT, token, isPost));

					if (!response.ok) {
						if (!failSilently) {
							await errorHandler.handle(response);
						}
						return;
					}

					const stringBody = await response.text();
					try {
						json = JSON.parse(stringBody);
					} catch (error: any) {
						json = stringBody;
					}
					if (isMounted.current) {
						const message = response.status <= 400 ? null : `${response.status}: ${response.statusText}`;
						setState({
							data: json,
							success: response.status <= 400,
							error: message ? { message: message } : null,
							loading: false,
						});
					}
				} catch (error: any) {
					if (!failSilently) {
						await errorHandler.handle(error);
					}
					return;
				}
			};

			call().then();
		},
		// eslint-disable-next-line
		triggers ? [fullURL, ...triggers] : [fullURL],
	);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	return state;
}
