//Node Modules
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import * as portals from 'react-reverse-portal';

//Material
import { Drawer as MuiDrawer, Box, Typography } from '@mui/material';

//Internal
import { UserState } from '../../../../store/userReducer';
import { StoreState } from '../../../../store/store';
import { CompanySection } from './CompanySection';
import { AdminSection } from './AdminSection';
import { UserSection } from './UserSection';
import { useAuth0 } from '@auth0/auth0-react';
import UserMenu from './UserMenu';
import { ROLES } from '../../../Shared/constants/roles';

export const Drawer = (props: { portalNode: any; setClose?: () => void }): ReactElement | null => {
	const { portalNode, setClose } = props;
	const userState: UserState = useSelector((state: StoreState) => state.user);
	const { user } = useAuth0();

	const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
	const open = Boolean(menuAnchor);

	const openMenu = (event: React.MouseEvent<HTMLElement>) => {
		setMenuAnchor(event.currentTarget);
	};

	const adminSectionOpen = userState.role >= ROLES.AUTOMATIT;

	return userState.id.length === 0 ? (
		<></>
	) : (
		<MuiDrawer
			variant="permanent"
			sx={{
				width: '250px',
			}}
			PaperProps={{
				id: 'test',
				sx: {
					width: '250px',
					border: 'none',
					boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 4px',
				},
			}}
		>
			<Box onClick={setClose}>
				<CompanySection />
				<Box
					sx={
						{
							// height: 'calc(100vh - 115px)',
							// display: 'flex',
							// flexDirection: 'column',
							// overflowY: 'scroll',
							// '&::-webkit-scrollbar': {
							// 	width: '6px',
							// },
							// '&:hover::-webkit-scrollbar-thumb': {
							// 	visibility: 'visible',
							// },
							// '&::-webkit-scrollbar-thumb': {
							// 	backgroundColor: 'rgba(128, 128, 128, 0.4)',
							// 	borderRadius: '50px',
							// 	backgroundClip: 'content-box',
							// 	visibility: 'hidden',
							// },
							// '&::-webkit-scrollbar-track': {
							// 	borderRadius: '50px',
							// },
						}
					}
				>
					{adminSectionOpen && <AdminSection />}
					<UserSection />
				</Box>
			</Box>

			<portals.InPortal node={portalNode}>
				<Box
					sx={{
						border: '1px solid rgba(0, 0, 0, 0.23)',
						borderRadius: '50px',
						height: '56px',
						padding: '8px 12px',
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
						width: '174px',
					}}
				>
					<Box
						sx={{
							height: '42px',
							width: '42px',
							minWidth: '42px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginRight: '8px',
							cursor: 'pointer',
						}}
						onClick={openMenu}
					>
						<img
							src={user?.picture}
							referrerPolicy="no-referrer"
							alt="User Icon"
							style={{ maxHeight: '42px', maxWidth: '42px', borderRadius: '50%' }}
						/>
					</Box>
					<Box
						sx={{
							height: '42px',
							display: 'flex',
							width: '100px',
							justifyContent: 'flex-start',
							alignItems: 'center',
						}}
					>
						<Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} color="primary">
							{userState.lastName}, {userState.firstName}
						</Typography>
					</Box>
				</Box>
				<UserMenu open={open} menuAnchor={menuAnchor} setMenuAnchor={setMenuAnchor} />
			</portals.InPortal>
		</MuiDrawer>
	);
};
