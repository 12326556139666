//Internal
import { CreateUser, DeleteUser, EditUser, EditUserEntityRelationships, RestoreUser } from '../../ManageUsers/components';
import { CreateCard, DeleteCard, EditCard } from '../../ManageCards/components';
import UserDetailsTableRows from '../../ManageUsers/components/UserDetailsTableRows';
import { ImpersonateUser } from '../components';
import { NodeTypes } from '../interfaces';
import {
	CreateEntity,
	DeleteEntity,
	EditEntity,
	EditEntityCardRelationships,
	EditEntityChildrenRelationships,
	RestoreEntity,
} from '../../ManageEntities/components';
import EditDashboard from '../../ManageDashboards/components/EditDashboard';
import { DeleteDashboard } from '../../ManageDashboards/components/DeleteDashboard';
import { CreateDashboard } from '../components/CreateDashboard';

export const NODE_TYPES: NodeTypes = {
	Card: {
		name: 'Card',
		route: '/cards',
		createComponent: (reRenderComponent: Function) => <CreateCard reRenderComponent={reRenderComponent} />,
		editComponent: (card: any, reRenderComponent: Function) => <EditCard card={card} reRenderComponent={reRenderComponent} />,
		deleteComponent: (card: any, reRenderComponent: Function) => (
			<DeleteCard deleteEmbedCardId={card.id} name={card.name} reRenderComponent={reRenderComponent} />
		),
		hasSoftDelete: true,
	},
	Entity: {
		name: 'Entity',
		route: '/entities',
		createComponent: (reRenderComponent: Function) => <CreateEntity reRenderComponent={reRenderComponent} />,
		relationshipsComponent: (entity: any, reRenderComponent: Function) => (
			<EditEntityCardRelationships entity={entity} reRenderComponent={reRenderComponent} />
		),
		editComponent: (entity: any, reRenderComponent: Function) => (
			<EditEntity entity={entity} reRenderComponent={reRenderComponent} />
		),
		deleteComponent: (entity: any, reRenderComponent: Function) => (
			<DeleteEntity deleteEntityId={entity.id} name={entity.name} reRenderComponent={reRenderComponent} />
		),
		restoreComponent: (entity: any, reRenderComponent: Function) => (
			<RestoreEntity id={entity.id} name={entity.name} reRenderComponent={reRenderComponent} />
		),
		editEntitiesSet: (entity: any, reRenderComponent: Function) => (
			<EditEntityChildrenRelationships entity={entity} reRenderComponent={reRenderComponent} />
		),
		hasSoftDelete: true,
	},
	User: {
		name: 'User',
		route: '/users',
		impersonation: (user: any) => <ImpersonateUser userId={user.id} />,
		createComponent: (reRenderComponent: Function) => <CreateUser reRenderComponent={reRenderComponent} />,
		relationshipsComponent: (user: any, reRenderComponent: Function) => (
			<EditUserEntityRelationships user={user} reRenderComponent={reRenderComponent} />
		),
		editComponent: (user: any, reRenderComponent: Function) => <EditUser user={user} reRenderComponent={reRenderComponent} />,
		deleteComponent: (user: any, reRenderComponent: Function) => (
			<DeleteUser
				deleteUserId={user.id}
				name={`${user.firstName} ${user.lastName}`}
				reRenderComponent={reRenderComponent}
			/>
		),
		restoreComponent: (user: any, reRenderComponent: Function) => (
			<RestoreUser id={user.id} name={user.name} reRenderComponent={reRenderComponent} />
		),
		detailsComponentRows: (nodeData: any) => (
			<UserDetailsTableRows
				node={nodeData.node}
				nodeCurrent={nodeData.nodeCurrent}
				setNodeCurrent={nodeData.setNodeCurrent}
			/>
		),
		hasSoftDelete: true,
	},
	Node: {
		name: 'Node',
		route: '/nodes',
		deleteComponent: () => null,
		createComponent: () => null,
		editComponent: () => null,
	},
	Dashboard: {
		name: 'Dashboard',
		route: '/dashboards/list',
		createComponent: (reRenderComponent: Function) => <CreateDashboard reRenderComponent={reRenderComponent} />,
		deleteComponent: (dashboard: any, reRenderComponent: Function) => <DeleteDashboard name={dashboard.name} reRenderComponent={reRenderComponent} id={dashboard.id} />,
		editComponent: (dashboard: any, reRenderComponent: Function) => (
			<EditDashboard dashboard={dashboard} reRenderComponent={reRenderComponent} />
		),
	},
};
