//Node Modules
import React from 'react';

//Material UI
import { Box } from '@mui/material';
import { NodeType } from '../../../Shared/interfaces';
import UserTable from './UserTable';
import { EntityTable } from './EntityTable';
import { InstanceTable } from './InstanceTable';
import { CardData } from './CardData';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//Internal

export const CardDetailsContent = (props: { tab: number; id: string | undefined; nodeType: NodeType; data: any }) => {
	const { tab, data } = props;
	const theme = useTheme();

	const standardScreen = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<>
			<Box
				hidden={tab !== 0}
				sx={{
					width: standardScreen ? '85%' : '100%',
					overflowY: 'auto',
					'&::-webkit-scrollbar': {
						background: '#ffffff',
						borderRadius: '10px',
						width: '.75em',
						[theme.breakpoints.down('md')]: {
							display: 'none',
						},
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#00000023',
						border: '4px solid transparent',
						borderRadius: '9px',
						backgroundClip: 'content-box',
					},
					'&::-webkit-scrollbar-track': {
						background: '#ffffff',
						borderRadius: '10px',
						outline: '#00000023 solid 2px',
						zIndex: 100,
					},
					'&::-webkit-scrollbar-button': {
						height: '2.5%',
					},
				}}
			>
				<CardData data={data} />
			</Box>
			<Box hidden={tab !== 1} sx={{ width: standardScreen ? '85%' : '100%', height: '100%' }}>
				<InstanceTable instances={data.instances} />
			</Box>
			<Box hidden={tab !== 2} sx={{ width: standardScreen ? '85%' : '100%', height: '100%' }}>
				<UserTable users={data.users} />
			</Box>
			<Box hidden={tab !== 3} sx={{ width: standardScreen ? '85%' : '100%', height: '100%' }}>
				<EntityTable entities={data.entities} />
			</Box>
		</>
	);
};
