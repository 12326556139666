//Node Module
import { ReactElement } from 'react';

//Material UI
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//Internal
import { generateNodeBars } from '../../../../utils';
import { useFetch } from '../../hooks/useFetch';
import { NodeFull, NodeType } from '../../interfaces';
import { ResponseHandler } from '..';
import { useWindowHeight } from '../../hooks/useWindowHeight';

export interface NodeListSearchCriteria {
	property: keyof NodeFull;
	value: string;
}

interface NodeListSearchParams {
	[key: string]: any;
}

const NodeList = (props: {
	nodeType: NodeType;
	searchCriteria: NodeListSearchCriteria;
	searchParams?: NodeListSearchParams;
	reRenderComponent: Function;
	reRender: Boolean;
}): ReactElement => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));
	const { nodeType, searchCriteria, searchParams, reRenderComponent, reRender } = props;
	const { height } = useWindowHeight(-100);

	let url = nodeType.route;

	if (searchParams) {
		const params = new URLSearchParams();
		for (const [name, value] of Object.entries(searchParams)) {
			params.set(name, JSON.stringify(value));
		}
		url = `${url}?${params.toString()}`;
	}

	const request = useFetch<NodeFull[]>(url, true, [reRender]);

	return (
		<ResponseHandler request={request}>
			<Box id="NodeList" sx={{ width: '100%' }}>
				<Box
					sx={{
						maxHeight: mobile ? height - 156 : height,
						overflowY: 'auto',
						overflowX: 'hidden',
						width: '96%',
						maxWidth: '100vw',
						display: 'flex',
						padding: '0 2%',
						flexWrap: 'wrap',
						justifyContent: 'center',
						'&::-webkit-scrollbar': {
							background: '#ffffff',
							borderRadius: '10px',
							width: '1.25em',
							[theme.breakpoints.down('md')]: {
								display: 'none',
							},
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#00000023',
							border: '4px solid transparent',
							borderRadius: '9px',
							backgroundClip: 'content-box',
						},
						'&::-webkit-scrollbar-track': {
							background: '#ffffff',
							borderRadius: '10px',
							outline: '#00000023 solid 2px',
							zIndex: 100,
						},
						'&::-webkit-scrollbar-button': {
							height: '2.5%',
						},
						[theme.breakpoints.down('md')]: {
							width: '100%',
						},
					}}
				>
					{request.data ? generateNodeBars(request.data, searchCriteria, nodeType, reRenderComponent) : null}
				</Box>
			</Box>
		</ResponseHandler>
	);
};

export default NodeList;
