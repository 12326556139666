//Node Modules
import React from 'react';

//Material UI
import { Box } from '@mui/material';
import { NodeType } from '../../../Shared/interfaces';
import { EntityTable } from './EntityTable';
import { InstanceTable } from './InstanceTable';
import CardTable from './CardTable';
import { UserData } from './UserData';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//Internal

export const UserDetailsContent = (props: { tab: number; id: string | undefined; nodeType: NodeType; data: any }) => {
	const { tab, data } = props;
	const theme = useTheme();

	const standardScreen = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<>
			{tab === 0 ? <Box
				sx={{
					width: standardScreen ? '85%' : '100%',
					overflowY: 'auto',
					'&::-webkit-scrollbar': {
						background: '#ffffff',
						borderRadius: '10px',
						width: '.75em',
						[theme.breakpoints.down('md')]: {
							display: 'none',
						},
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#00000023',
						border: '4px solid transparent',
						borderRadius: '9px',
						backgroundClip: 'content-box',
					},
					'&::-webkit-scrollbar-track': {
						background: '#ffffff',
						borderRadius: '10px',
						outline: '#00000023 solid 2px',
						zIndex: 100,
					},
					'&::-webkit-scrollbar-button': {
						height: '2.5%',
					},
				}}
			>
				<UserData data={data} />
			</Box> : null}
			{tab === 1 ? <Box sx={{ width: standardScreen ? '85%' : '100%', height: '100%' }}>
				<InstanceTable instances={data.instances} />
			</Box> : null}
			{tab === 2 ? <Box sx={{ width: standardScreen ? '85%' : '100%', height: '100%' }}>
				<CardTable cards={data.cards} />
			</Box> : null}
			{tab === 3 ? <Box sx={{ width: standardScreen ? '85%' : '100%', height: '100%' }}>
				<EntityTable entities={data.entities} />
			</Box> : null}
		</>
	);
};
