import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';

export const DetailsTable = (props: { rows: any; columns: any }) => {
	const { rows, columns } = props;
	const theme = useTheme();

	return (
		// <Paper sx={{ width: '100%', height: '100%', overflowY: 'hidden' }}>
		// 	<TableContainer sx={{ overflowX: 'scroll', height: '100%', overflowY: 'hidden' }}>
		// 		<Table
		// 			sx={{
		// 				borderCollapse: 'collapse',
		// 				height: '100%',
		// 				overflowY: 'hidden',
		// 			}}
		// 		>
		// 			<TableHead>
		// 				<TableRow
		// 					key="header"
		// 					sx={{
		// 						'& th': {
		// 							color: theme.palette.primary.contrastText,
		// 							backgroundColor: theme.palette.primary.main,
		// 						},
		// 					}}
		// 				>
		// 					{columns.map((column: any) => (
		// 						<TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
		// 							{column.label}
		// 						</TableCell>
		// 					))}
		// 				</TableRow>
		// 			</TableHead>
		// 			<TableBody sx={{ height: '100%', overflowY: 'scroll' }}>
		// 				{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => {
		// 					return (
		// 						<TableRow
		// 							onClick={row.onClickCallback ? row.onClickCallback : undefined}
		// 							hover
		// 							tabIndex={-1}
		// 							key={row.id}
		// 						>
		// 							{columns.map((column: any) => {
		// 								const value = row[column.id];
		// 								return (
		// 									<TableCell key={column.id} align={column.align}>
		// 										{column?.format ? column.format(value) : value}
		// 									</TableCell>
		// 								);
		// 							})}
		// 						</TableRow>
		// 					);
		// 				})}
		// 			</TableBody>
		// 		</Table>
		// 	</TableContainer>
		// 	<TablePagination
		// 		component="div"
		// 		rowsPerPageOptions={[15, 25, 100]}
		// 		count={rows.length}
		// 		rowsPerPage={rowsPerPage}
		// 		page={page}
		// 		onPageChange={handleChangePage}
		// 		onRowsPerPageChange={handleChangeRowsPerPage}
		// 	/>
		// </Paper>
		<Box
			id="box"
			sx={{
				height: '100%',
				width: '100%',
				minWidth: 0,
				minHeight: 0,

				'& .MuiDataGrid-columnHeaders': {
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
				},
				'& .MuiDataGrid-menuIconButton': {
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
				},
				'& .MuiDataGrid-sortIcon': {
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
				},
				'& .MuiDataGrid-columnSeparator': {
					display: 'none',
				},
			}}
		>
			<DataGrid
				sx={{
					width: '100%',
					minWidth: 0,
					minHeight: 0,
					border: 'none',
				}}
				rows={rows}
				columns={columns}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 25,
						},
					},
				}}
				pageSizeOptions={[25, 50, 100]}
				disableRowSelectionOnClick
				autoPageSize
			/>
		</Box>
	);
};
