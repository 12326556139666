//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { TableFieldReadOnlyProps } from '../interfaces/TableFieldReadOnlyProps';
import { TableCell, TableRow, TextField } from '@mui/material';

export const TableReadOnlyField = (props: TableFieldReadOnlyProps): ReactElement => {
	const { nodeCurrent, label, relatedValue, notValue } = props;

	const value = notValue ? !nodeCurrent[relatedValue] : nodeCurrent[relatedValue];

	return (
		<TableRow>
			<TableCell>{label}</TableCell>
			<TableCell sx={{ width: '85%' }}>
				<TextField fullWidth variant="standard" autoComplete="off" value={value} disabled />
			</TableCell>
		</TableRow>
	);
};
