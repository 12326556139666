import React, { useState } from 'react';
import { NodeDialog, NodeIconButton } from '../../Shared/components';
import { DASHBOARD_EDIT_URL } from '../../Shared/constants/routes';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { SelectConnectedUsers } from './SelectConnectedUsers';
import { EditDashboardModel } from '../types/EditDashboardModel';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardSlice } from '../../../store/dashboardReducer';
import { StoreState } from '../../../store/store';

const EditDashboard = (props: {dashboard: EditDashboardModel, reRenderComponent: any}): React.ReactElement => {
	const {dashboard, reRenderComponent} = props;

	const initUsersId = dashboard.users.map((user) => user.id)

	const [isPublic, setIsPublic] = useState<boolean>(dashboard.isPublic);
	const [connectedUsers, setConnectedUsers] = useState<string[]>(initUsersId);

	const dispatch = useDispatch();
	const dashboards = useSelector((state: StoreState) => state.dashboards);

	const dashboardData = {
		...dashboard,
		isPublic: isPublic,
		users: connectedUsers,
	}

	const onPublicChange = (event: any) => {
		const value = event.target.value;
		setIsPublic(value === '1')
	}

	const onClose = () => {
		setIsPublic(dashboard.isPublic)
		setConnectedUsers(initUsersId)
	}

	const successCallback = (_newState: any) => {
		const updatedDashboards = getUpdatedDashboards(dashboards.dashboards, dashboardData);

		dispatch(
			dashboardSlice.actions.update({ ...dashboards, dashboards: updatedDashboards })
		);
	}

	return <NodeDialog
		ButtonComponent={NodeIconButton}
		url={DASHBOARD_EDIT_URL}
		node={dashboardData}
		dialogText={'Edit'}
		dialogTitle={'Edit Dashboard'}
		reRenderComponent={reRenderComponent}
		buttonColor={'primary'}
		variant={'contained'}
		Icon={EditOutlinedIcon}
		resetContent={onClose}
		successCallback={successCallback}
	>
		<FormControl>
			<Typography>Public</Typography>
			<Select
				onChange={onPublicChange}
				defaultValue={isPublic ? '1' : '2'}
				labelId="is-public"
				id="is-public"
				label="Public"
			>
				<MenuItem value={'1'}>Yes</MenuItem>
				<MenuItem value={'2'}>No</MenuItem>
			</Select>
			<Typography>Users</Typography>
			<SelectConnectedUsers selected={connectedUsers} onChange={setConnectedUsers} />
		</FormControl>
	</NodeDialog>
}

export default EditDashboard;

const getUpdatedDashboards = (dashboards: Array<any>, updatedDashboard: any) => {
	const oldDashboards = [...dashboards]
	const index = dashboards.findIndex((dash) => dash.id === updatedDashboard.id);
	if (index !== -1) {
		oldDashboards[index] = updatedDashboard;
	} else {
		oldDashboards.push(updatedDashboard);
	}
	return oldDashboards;
};