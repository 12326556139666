//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { DialogContentText } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

//Internal
import { NodeDialog, NodeIconButton } from '../../Shared/components';
import { CARD_DELETE_URL } from '../../Shared/constants/routes';
import { NodeBase } from '../../Shared/interfaces/';
import { DeleteCardProps } from '../interfaces';

export const DeleteCard = (props: DeleteCardProps): ReactElement => {
	const { reRenderComponent, name, deleteEmbedCardId } = props;

	const card: NodeBase = {
		name: name,
		deleteEmbedCardId: deleteEmbedCardId,
	};

	return (
		<NodeDialog
			ButtonComponent={NodeIconButton}
			url={CARD_DELETE_URL}
			node={card}
			dialogText={'Delete'}
			dialogTitle={'Delete Card'}
			reRenderComponent={reRenderComponent}
			Icon={DeleteOutlineIcon}
		>
			<DialogContentText>{`Are you sure you want to delete card ${name}?`}</DialogContentText>
		</NodeDialog>
	);
};
