import * as React from 'react';
import { DetailsTable } from '../../../Shared/components/DetailsTable';
//import { IconButton } from '@mui/material';
//import NavigateNextIcon from '@mui/icons-material/NavigateNext';
//import { useNavigate } from 'react-router-dom';

export default function UserTable(props: { users: any }) {
	const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short', timeZone: 'US/Arizona' });
	//const navigate = useNavigate();
	const { users } = props;

	const columns = [
		{ field: 'lastName', headerName: 'Last Name', minWidth: 140 },
		{ field: 'firstName', headerName: 'First Name', minWidth: 140 },
		{ field: 'email', headerName: 'Email', minWidth: 170 },
		{ field: 'id', headerName: 'Id', minWidth: 300 },
		{
			field: 'createdOn',
			headerName: 'Created',
			minWidth: 180,
			align: 'left',
			valueFormatter: (params: any) => {
				const date = new Date(params?.value);
				return dateFormatter.format(date);
			},
		},
		{
			field: 'modifiedOn',
			headerName: 'Modified',
			minWidth: 180,
			align: 'left',
			valueFormatter: (params: any) => {
				const date = new Date(params?.value);
				return dateFormatter.format(date);
			},
		},
		// {
		// 	field: 'more',
		// 	minWidth: 25,
		// 	alight: 'left',
		// 	format: (value: string) => {
		// 		return (
		// 			<IconButton onClick={() => navigate(`/users/${value}`)}>
		// 				<NavigateNextIcon />
		// 			</IconButton>
		// 		);
		// 	},
		// },
	];

	const rows = users.map((user: any) => {
		const userRow = {
			key: user.id,
			name: `${user.firstName} ${user.lastName}`,
			more: user.id,
			...user,
		};

		return userRow;
	});

	return <DetailsTable rows={rows} columns={columns} />;
}
