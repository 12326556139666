import React from 'react';
import Selector from './Selector';
import { useFetch } from '../../hooks/useFetch';
import { CARD_DOMO_COLUMNS } from '../../constants/routes';
import { Loading } from '../Loading';

interface DOMOColumnsSelectorProps {
	qsId: string;
	onSelect: (value: string) => void;
}

const DOMOColumnsSelector = (props: DOMOColumnsSelectorProps): React.ReactElement => {
	const {qsId, onSelect} = props;

	// Get columns from API;
	const response = useFetch(CARD_DOMO_COLUMNS, true, [qsId], {datasetId: qsId}, true)

	const handleChange = (value: string) => {
		onSelect(value);
	}

	return (response.success ? <Selector onChange={handleChange} options={response.data} /> : <Loading />)
}

export default DOMOColumnsSelector;