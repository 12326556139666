//import React, { useState } from 'react';
import { DetailsTable } from '../../../Shared/components/DetailsTable';
// import { IconButton } from '@mui/material';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import { useNavigate } from 'react-router-dom';
// import SearchIcon from '@mui/icons-material/Search';
// import { InstanceViewer } from './InstanceViewer';

export const InstanceTable = (props: { instances: any }) => {
	const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short', timeZone: 'US/Arizona' });
	// const [instanceViewOpen, setInstanceViewOpen] = useState<boolean>(false);
	// const [selectedInstance, setSelectedInstance] = useState<string>('');
	// const [selectedName, setSelectedName] = useState<string>('');

	// const navigate = useNavigate();
	const { instances } = props;

	// const triggerInstanceViewer = (instanceId: string) => {
	// 	setSelectedInstance(instanceId);
	// 	getInstanceName(instanceId);
	// 	setInstanceViewOpen(true);
	// };

	// const getInstanceName = (instanceId: string) => {
	// 	const instance = instances.filter((instance: any) => instance.id === selectedInstance);
	// 	setSelectedName(instance[0].name);
	// };

	const columns = [
		{ field: 'name', headerName: 'Name', minWidth: 140 },
		{ field: 'id', headerName: 'Id', minWidth: 300 },
		{ field: 'dashboardId', headerName: 'Dashboard', minWidth: 300 },
		{
			field: 'createdOn',
			headerName: 'Created',
			minWidth: 180,
			align: 'left',
			valueFormatter: (params: any) => {
				const date = new Date(params?.value);
				return dateFormatter.format(date);
			},
		},
		{
			field: 'modifiedOn',
			headerName: 'Modified',
			minWidth: 180,
			align: 'left',
			valueFormatter: (params: any) => {
				const date = new Date(params?.value);
				return dateFormatter.format(date);
			},
		},
		// {
		// 	id: 'more',
		// 	minWidth: 25,
		// 	alight: 'left',
		// 	format: (value: string) => {
		// 		return (
		// 			<IconButton onClick={() => triggerInstanceViewer(value)}>
		// 				<SearchIcon />
		// 			</IconButton>
		// 		);
		// 	},
		// },
	];

	const rows = instances.map((instance: any) => {
		const instanceRow = {
			key: instance.id,
			more: instance.id,
			...instance,
		};

		return instanceRow;
	});

	return (
		<>
			<DetailsTable rows={rows} columns={columns} />
			{/* <InstanceViewer
				instanceViewOpen={instanceViewOpen}
				setInstanceViewOpen={setInstanceViewOpen}
				selectedInstance={selectedInstance}
				selectedName={selectedName}
			/> */}
		</>
	);
};
