//Node Modules
import React, { ReactElement } from 'react';

//Internal
import { NodeFull, NodeType } from '../modules/Shared/interfaces';
import { NodeBar, NoResults } from '../modules/Shared/components';

export interface NodeBarsSearchCriteria {
	property: keyof NodeFull;
	value: string;
}

export const generateNodeBars = (
	nodes: NodeFull[],
	searchCriteria: NodeBarsSearchCriteria,
	nodeType: NodeType,
	reRenderComponent: Function,
): ReactElement | ReactElement[] => {
	if (!nodes.length) {
		return <NoResults />;
	}

	let filteredNodes: NodeFull[] = [];

	if (searchCriteria.value) {
		filteredNodes = applySearchCriteria(searchCriteria, nodes);
	}

	if (!searchCriteria.value && filteredNodes.length === 0) {
		filteredNodes = nodes;
	}

	const bars: ReactElement[] = filteredNodes.map((node: NodeFull): ReactElement => {
		return <NodeBar nodeType={nodeType} key={node.id} node={node} reRenderComponent={reRenderComponent} />;
	});

	if (bars.length > 0) return bars;
	return <NoResults />;
};

const applySearchCriteria = (searchCriteria: NodeBarsSearchCriteria, nodes: NodeFull[]): NodeFull[] => {
	const searchProp = searchCriteria.property;
	return nodes.filter((node: NodeFull): boolean | undefined => {
		switch (searchProp) {
			case 'id':
				return node[searchProp].includes(searchCriteria.value);
			case 'name':
				const searchCriteriaLower = searchCriteria.value.toLowerCase();
				return !!node[searchProp] && node[searchProp]?.toLowerCase().includes(searchCriteriaLower);
			default:
				return true;
		}
	});
}
