import * as yup from 'yup';

//TODO - yup Typings?
export const editEntityValidator = yup.object().shape({
	name: yup.string().min(3).trim(),
	// "cardFiltersKVP":[{"key":"foo","value":"bar"}]
	cardFiltersKVP: yup.array().of(yup.object({
		key: yup.string().required(),
		value: yup.string().required()
	})).optional(),
});

export const createEntityValidation = yup.object().shape({
	name: yup.string().min(3).trim().required(),
	graphData: yup.object({
		vertical: yup.string().min(3).required(),
		level: yup.string().min(3).required()
	}).required(),
});
