import * as React from 'react';
import { DetailsTable } from '../../../Shared/components/DetailsTable';
//import { IconButton } from '@mui/material';
//import NavigateNextIcon from '@mui/icons-material/NavigateNext';
//import { useNavigate } from 'react-router-dom';

export const InstanceTable = (props: { instances: any }) => {
	const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short', timeZone: 'US/Arizona' });
	//const navigate = useNavigate();
	const { instances } = props;

	const columns = [
		{ field: 'name', headerName: 'Name', minWidth: 140 },
		{ field: 'id', headerName: 'Id', minWidth: 300 },
		{ field: 'dashboardId', headerName: 'Dashboard', minWidth: 300 },
		{
			field: 'createdOn',
			headerName: 'Created',
			minWidth: 180,
			align: 'left',
			format: (params: any) => {
				const date = new Date(params?.value);
				return dateFormatter.format(date);
			},
		},
		{
			field: 'modifiedOn',
			headerName: 'Modified',
			minWidth: 180,
			align: 'left',
			valueFormatter: (params: any) => {
				const date = new Date(params?.value);
				return dateFormatter.format(date);
			},
		},
		// {
		// 	field: 'more',
		// 	minWidth: 25,
		// 	alight: 'left',
		// 	format: (value: string) => {
		// 		return (
		// 			<IconButton onClick={() => navigate(`/entities/${value}`)}>
		// 				<NavigateNextIcon />
		// 			</IconButton>
		// 		);
		// 	},
		// },
	];

	const rows = instances.map((instance: any) => {
		const instanceRow = {
			key: instance.id,
			more: instance.id,
			...instance,
		};

		return instanceRow;
	});

	return <DetailsTable rows={rows} columns={columns} />;
};
