//Node Modules
import React, { ReactElement, useState } from 'react';

//Material UI
import { Box, TextField, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

//Internal
import { NodeDialog, NodeIconButton } from '../../Shared/components';
import { CardFilter, NodeBase } from '../../Shared/interfaces';
import { CARD_EDIT_URL } from '../../Shared/constants/routes';
import SelectColumnName from '../../Shared/components/DOMO/SelectColumnName';
import { editCardValidation } from '../constants/validationSchemas';

interface ICard extends NodeBase {
	identifierField?: string;
}

export const EditCard = (props: { reRenderComponent: Function; card: ICard }): ReactElement => {
	const { card, reRenderComponent } = props;

	const startingFilters = (card?.filterFields as CardFilter[]) ?? [];

	const [name, setName] = useState<string>(card.name || '');
	const [identifierField, setIdentifierField] = useState<string>(card.identifierField || '');
	const [filterFields, setFilterFields] = useState<CardFilter[]>([...startingFilters]);

	//Make sure to use state variables if you want these to be updatable
	const cardData: NodeBase = {
		name: name,
		id: card.id,
		embedId: card.embedId,
		filterFields: filterFields,
		identifierField: identifierField,
	};

	const onClose = () => {
		setName(card.name || '')
		setIdentifierField(card.identifierField || '')
		setFilterFields(startingFilters)
	}

	return (
		<NodeDialog
			ButtonComponent={NodeIconButton}
			url={CARD_EDIT_URL}
			node={cardData}
			dialogText={'Edit'}
			dialogTitle={'Edit Card'}
			reRenderComponent={reRenderComponent}
			buttonColor={'primary'}
			dialogContentText="Editing card details. Name must be unique. Please include any relevant filter fields for data lookup in DOMO."
			Icon={EditOutlinedIcon}
			validationSchema={editCardValidation}
			resetContent={onClose}
		>
			<Box sx={{ marginTop: '10px' }}>
				<Typography sx={{ fontWeight: 700, fontSize: '14px', marginBottom: '5px' }}>Details</Typography>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Card Name"
					type="text"
					fullWidth
					variant="standard"
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
				<TextField
					disabled
					value={identifierField}
					fullWidth
					variant="standard"
					id="identifierField"
					label="Identity Column name"
				/>
				<Box sx={{ marginTop: '10px' }}>
					<SelectColumnName handleSelect={setIdentifierField} />
				</Box>
			</Box>
		</NodeDialog>
	);
};
