import React, { useState } from 'react';
import DOMODatasetSelector from './DOMOQuerysetSelector';
import { FormControl, FormControlLabel, FormLabel, Switch, TextField } from '@mui/material';
import DOMOColumnsSelector from './DOMOColumnsSelector';

interface SelectColumnNameProps {
	handleSelect: (value: string) => void;
}

const SelectColumnName = (props: SelectColumnNameProps): React.ReactElement => {
	const { handleSelect } = props;
	const [datasetId, setDatasetId] = useState<string | null>(null);
	const [manual, setManual] = useState<boolean>(false);

	const handleDSChange = (value: string) => {
		setDatasetId(value)
	}

	const onChange = (value: string) => {
		handleSelect(value);
	}

	const onSwitch = () => {
		setDatasetId(null);
		setManual(!manual);
	}

	const autoSelectByQS = <div>
		<DOMODatasetSelector onChange={handleDSChange} />
		{datasetId ? <DOMOColumnsSelector onSelect={onChange} qsId={datasetId} /> : null}
	</div>

	return (
		<FormControl fullWidth={true}>
			<FormLabel>Identifier Field</FormLabel>
			<FormControlLabel
				control={<Switch onChange={onSwitch} />}
				label="Manual"
				style={{whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis'}}
			/>
			{
				manual ?
					<TextField
						autoFocus
						onChange={(event) => handleSelect(event.target.value)}
						placeholder={'Field name'}
					/>
				:
					<div>
						{autoSelectByQS}
					</div>
			}
		</FormControl>
	)
}

export default SelectColumnName;