//Node Modules
import React, { ReactElement, useEffect, useState } from 'react';

//Material UI
import {
	Box,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import { NodeBase } from '../interfaces';
import { dashboardSlice } from '../../../store/dashboardReducer';
import { NodeDialog, CreateNodeButton, ResponseHandler } from '.';
import { DASHBOARD_CREATE_URL, DASHBOARD_LIST_FOR_USER, DASHBOARD_MANAGEMENT_LIST } from '../constants/routes';
import { useFetch } from '../hooks/useFetch';
import { useDispatch, useSelector } from 'react-redux';
import { UserState } from '../../../store/userReducer';
import { StoreState } from '../../../store/store';
import { ROLES } from '../constants/roles';

//Internal

interface NewDashboard extends NodeBase {
	name: string;
    needDuplicate: boolean;
    sourceDashboardId: string;
}

export const CreateDashboard = (props: { reRenderComponent: Function }): ReactElement => {
	const [name, setName] = useState<string>('');
	const [needDuplicate, setNeedDuplicate] = useState<boolean>(false);
	const [sourceDashboardID, setSourceDashboardID] = useState<string>('');
	const dispatch = useDispatch();

	const { reRenderComponent } = props;

	const resetContent = (): void => {
		setName('');
		setNeedDuplicate(false);
		setSourceDashboardID('');
	};

	const newDashboard: NewDashboard = {
		name: name,
		needDuplicate: needDuplicate,
		sourceDashboardId: sourceDashboardID,
	};

	const successCallback = (data: any) => {
		console.log(data);
		dispatch(dashboardSlice.actions.update({ dashboards: data, ...data }));
	};

	const handleSwitch = () => {
		setNeedDuplicate(!needDuplicate);
	};

	return (
		<NodeDialog
			ButtonComponent={CreateNodeButton}
			url={DASHBOARD_CREATE_URL}
			resetContent={resetContent}
			node={newDashboard}
			dialogText={'Create'}
			dialogTitle={'Create Dashboard'}
			reRenderComponent={reRenderComponent}
			successCallback={successCallback}
			dialogContentText="Creating dashboard. Please enter a name."
		>
			<Box sx={{ marginTop: '10px' }}>
				<Typography sx={{ fontWeight: 700, fontSize: '14px' }}>Details</Typography>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Name"
					type="text"
					fullWidth
					variant="standard"
					value={name}
					onChange={(e: any) => setName(e.target.value)}
				/>
				<FormControlLabel control={<Switch onChange={handleSwitch} />} label="Copy another dashboard" />
				{needDuplicate && <SelectDashboard onSelect={setSourceDashboardID} />}
			</Box>
		</NodeDialog>
	);
};
export const SelectDashboard = (props: { onSelect: (value: string) => void }): ReactElement => {
	const { onSelect } = props;

	const userState: UserState = useSelector((state: StoreState) => state.user);

	const [options, setOptions] = useState<{ id: string; name: string }[]>([]);

	// The client role should request only related dashboards. Admin and automatit users will see all dashboards.
	const requestURL: string = userState.role >= ROLES.AUTOMATIT ? DASHBOARD_MANAGEMENT_LIST : DASHBOARD_LIST_FOR_USER;
	const response = useFetch<{ id: string; name: string }[]>(requestURL);

	useEffect(() => {
		if (response.success && response.data) {
			const _options = response.data.map((dashboard) => {
				return { id: dashboard.id, name: dashboard.name };
			});

			setOptions(_options);
		}
	}, [response.success, response.data]);

	return (
		<ResponseHandler request={response}>
			<DuplicateDashboardList dashboards={options} onSelect={onSelect} />
		</ResponseHandler>
	);
};
export const DuplicateDashboardList = (props: {
	dashboards: { id: string; name: string }[];
	onSelect: (value: string) => void;
}) => {
	const { dashboards, onSelect } = props;

	const handleChange = (event: SelectChangeEvent): void => {
		onSelect(event.target.value);
	};

	return (
		<FormControl sx={{ m: 1, minWidth: 300 }}>
			<InputLabel id="select-dashboard-to-copy">Select dashboard</InputLabel>
			<Select
				defaultValue={''}
				onChange={handleChange}
				labelId="select-dashboard-to-copy"
				label={'Select dashboard to copy'}
			>
				{dashboards.map((dashboard, index) => {
					return (
						<MenuItem key={`dashboard-to-copy-${index}`} value={dashboard.id}>
							{dashboard.name}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};
