//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { Typography } from '@mui/material';

export const NoResults = (): ReactElement => {
	return (
		<Typography
			sx={{ maxWidth: '100vw', display: 'flex', flexDirection: 'column', padding: '50px 50px', alignItems: 'center' }}
		>
			{'No Results Found'}
		</Typography>
	);
};
