//Node Modules
import { useNavigate } from 'react-router-dom';
import React, { ReactElement } from 'react';

//Material UI
import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//Internal
import { NODE_TYPES } from '../../constants/NodeTypes';
import { NodeBarProps } from '../../interfaces';
import icon from '../../../Core/assets/account_picture_icon.svg';


export const NodeBar = (props: NodeBarProps): ReactElement => {
	const { node, nodeType, reRenderComponent } = props;
	const navigate = useNavigate();
	const theme = useTheme();
	const hasIcon = nodeType === NODE_TYPES.Entity || nodeType === NODE_TYPES.User || nodeType === NODE_TYPES.Node;

	return (
		<Card
			sx={{
				display: 'flex',
				flex: '1 0 265px',
				justifyContent: 'center',
				flexDirection: 'column',
				border: '2px solid #00000020',
				boxShadow: 'none',
				margin: '10px',
				borderRadius: '16px',
				padding: '20px',
				maxHeight: '400px',
				maxWidth: '350px',
			}}
		>
			<CardContent
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					padding: '0px',
					marginBottom: '15px',
					width: '100%',
					textAlign: 'center',
				}}
			>
				{hasIcon ? (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							marginBottom: '10px',
						}}
					>
						<img src={icon} alt="Logo" style={{ height: '75px' }} />
					</Box>
				) : null}
				<Typography variant="h5" sx={{ textAlign: 'center', color: theme.palette.primary.main }}>
					{node.name ?? `${node.firstName}  ${node.lastName}`}
				</Typography>
				<Typography sx={{ fontSize: '12px', color: theme.palette.primary.main }}>{node.id}</Typography>
			</CardContent>

			<CardActions sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
				<Button
					onClick={(): void => navigate(`${nodeType.route}/${node.id}`)}
					variant="text"
					size="large"
					color={'primary'}
					sx={{
						textTransform: 'capitalize',
						textDecoration: 'none',
						padding: '0',
						'&:hover': {
							background: 'none',
						},
					}}
				>
					Manage
				</Button>
				{nodeType.impersonation ? nodeType.impersonation(node) : null}
				{nodeType.relationshipsComponent ? nodeType.relationshipsComponent(node, reRenderComponent) : null}
				{nodeType.editEntitiesSet ? nodeType.editEntitiesSet(node, reRenderComponent) : null}

				<Box sx={{ display: 'flex', marginTop: '16px', marginLeft: '0 !important' }}>
					{nodeType.editComponent(node, reRenderComponent)}
					{node.deleted && nodeType.restoreComponent ? (
						<>{nodeType.restoreComponent(node, reRenderComponent)}</>
					) : (
						<>{nodeType.deleteComponent(node, reRenderComponent)}</>
					)}
				</Box>
			</CardActions>
		</Card>
	);
};
